import React from "react";
import styles from "./landers.module.scss";
import isMobile from "./IsMobile";

export const mainCTAs = (type) => (
  <div className={styles.mainCTAs}>
    <a href="/contact-us/" className={styles.primary}>
      Contact us
    </a>
  </div>
);

const Banner = ({ h1, u, txt, span, p, img }) => {
  return (
    <div className={styles.banner}>
      <div className={styles.bannerLeft}>
        <h1>{h1} </h1>
        <h1>
          <u>{u}</u>{" "}
          <txt>
            {txt} <br />
          </txt>{" "}
          <span>{span}</span>
        </h1>
        <p>{p}</p>
        {mainCTAs()}
      </div>
      {!isMobile() && <img src={img} alt="" />}
    </div>
  );
};

export default Banner;
