import React from "react";
import Carousel from 'react-bootstrap/Carousel'
import Styles from "./SVUnique.module.scss"

const SVUnique = () => {
	const cards = [
		{
			path: require("../../../images/LP/Home/svUnique/unicorns.png"),
			title: 'Top Indian unicorns love us',
			desc: 'From promising upstarts to growing SMEs and Enterprises - top companies rely on SpringVerify to screen their candidates before onboarding.'
		},
		{
			path: require("../../../images/LP/Home/svUnique/experience.png"),
			title: 'Delightful candidate experience',
			desc: 'Our friction-free process offers your candidates a seamless screening experience from form submission to status tracking and process completion.'
		},
		{
			path: require("../../../images/LP/Home/svUnique/tailored.png"),
			title: 'Tailored solutions for your needs',
			desc: 'We know your business is unique. That’s why we carefully analyze and understand your business first to offer solutions purely based on your needs.'
		},
		{
			path: require("../../../images/LP/Home/svUnique/dedicated.png"),
			title: 'Dedicated customer support',
			desc: `As soon as you sign up, we assign a Dedicated Customer Success Manager to assist you proactively throughout your journey with us. 
			Plus, our support team delivers an outstanding service to help you navigate through challenges.`
		},
		{
			path: require("../../../images/LP/Home/svUnique/bulk.png"),
			title: 'Supports bulk and seasonal hiring',
			desc: 'Empower high-volume hiring by running checks on multiple candidates simultaneously. Cut costs, save time and manage resources efficiently.'
		},
	];
	return (
		<div className={Styles.SVUniqueWrap}>
			<section>
				<h2>What makes SpringVerify unique?</h2>
				<div className={Styles.SVUnique}>
					{cards.map((card, i) => (
						<div className={Styles.SVUniqueCard} key={i}>
							<img src={card.path} />
							<div className={Styles.SVUniqueContent}>
								<h5>{card.title}</h5>
								<p>{card.desc}</p>
							</div>
						</div>
					))}
				</div>
				<Carousel className='SVUniqueSlider'>
					{cards.map((card, i) => (
						<Carousel.Item className={Styles.SVUniqueCard} key={i}>
							<img src={card.path} />
							<div className={Styles.SVUniqueContent}>
								<h5>{card.title}</h5>
								<p>{card.desc}</p>
							</div>
						</Carousel.Item>
					))}
				</Carousel>
			</section>
		</div>
	);
};

export default SVUnique;
