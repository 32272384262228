import React from "react";
import styles from "../../components/Landers/landers.module.scss";
const requestImageFile = require.context(
  "../../images/LP/Home/home-brands",
  true,
  /.svg$/
);

export default () => (
  <section className={styles.brands}>
    <h2>Brands you trust, trust us</h2>
    <div className={styles.listWrap}>
      <div className={styles.list}>
        {[
          "cred",
          "flipkart",
          "open",
          "razorpay",
          "meesho",
          "instamojo",
          "wazirx",
          "slice",
          "apollo",
          "cashfree",
        ].map((e, i) => (
          <div className={styles.imgWrap}>
            <img key={i} src={requestImageFile(`./${e}.svg`)} alt="" />
          </div>
        ))}
      </div>
    </div>
  </section>
);
