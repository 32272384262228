import React from "react";
import Styles from "./VerificationFunnel.module.scss";
import Arrow from "../../../images/LP/Home/api/right-arrow.svg";

const VFunnel = () => {
  const cards = [
    {
      path: require("../../../images/LP/Home/verificationFunnel/better.svg"),
      title: "Better productivity with workflow automation",
      desc: `Save time, automate repetitive tasks and eliminate manual errors. Manage high-volume verifications, track progress, 
            and hire with confidence using SpringVerify.`,
      link: "/api-integrations/",
    },
    {
      path: require("../../../images/LP/Home/verificationFunnel/intergrate.svg"),
      title: "Integrates with your existing HR systems",
      desc:
        "SpringVerify plugs with your existing ATS and HRIS tools easily. You can rest assured that you don’t have to dedicate time and effort to get started.",
      link: "/integrations/",
    },
    {
      path: require("../../../images/LP/Home/verificationFunnel/world.svg"),
      title: "World’s first instant KYC on Whatsapp",
      desc:
        "Make instant verifications a reality with SpringVerify on Whatsapp. Save time, solidify trust and hire on the go.",
      link: "https://whatsapp.springverify.com/",
    },
    {
      path: require("../../../images/LP/Home/verificationFunnel/plus.svg"),
      title: "Plus, your security is our priority",
      desc: `We secure all your data with end-to-end encryptions using advanced security controls and complete information governance. 
            Rightly so, we are certified by PCI and ISO 27001:9000.`,
      link: "/security/",
    },
  ];
  return (
    <section>
      <div className={Styles.VFunnelWrap}>
        <div className={Styles.VFunnelHeader}>
          <h2>
            Everything you need to build your <br />
            preferred <u>verification funnel</u>
          </h2>
          <p>
            Make timely and informed decisions while hiring with SpringVerify's
            suite of services
          </p>
        </div>
        <div className={Styles.VFunnelCardWrap}>
          {cards.map((card, i) => (
            <a target="_blank" className={Styles.VFunnelCard} key={i}>
              <div className={Styles.VFunnelCardLeft}>
                <img src={card.path} />
              </div>
              <div className={Styles.VFunnelCardRight}>
                <h5>{card.title}</h5>
                <p>{card.desc}</p>
                <a href={card.link}>
                  Learn More
                  <img src={Arrow} />
                </a>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VFunnel;
