import React from "react";
import SEO from "../components/Landers/SEO";
import Header from "../components/Landers/Header";
import Banner from "../components/Landers/HomeBanner";
import HomeBrands from "../components/Landers/HomeBrands";
import SpringVerifyUnique from "../components/Landers/SpringVerifyUnique";
import VerificationExp from "../components/Landers/API/VerificationExp";
import VerificationFunnel from "../components/Landers/VerificationFunnel";
import Checks from "../components/Landers/Checks";
import Testimonials from "../components/Landers/Testimonials";
import GetStarted from "../components/Landers/GetStarted";
import Footer from "../components/Landers/Footer";
import styles from "../components/Landers/landers.module.scss";
import DataInfo from "../components/Data";
import "./styles.scss";

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default (props) => {
  return (
    <div className={styles.solutions}>
      <SEO
        title="Employment Background Screening Service | SpringVerify India"
        desc="SpringVerify India provides you with instant,reliable and accurate background checks for a safe and secure workplace."
        url="https://in.springverify.com/"
        img_url=""
      />
      <div className={styles.topSection}>
        <Header />
        <Banner
          h1="Reliable background verifications."
          u="Purpose-built"
          txt="for"
          span="high-growth companies."
          p="From agile startups to top enterprises - companies trust SpringVerify to make smart hiring decisions with prompt, reliable, and accurate background checks."
          img="https://assets-sv-in.gumlet.io/LP/Home/banner.png"
        />
      </div>
      <HomeBrands />
      <div className="HGreySection">
        <SpringVerifyUnique />
        <VerificationExp />
        <VerificationFunnel />
        <Checks data={DataInfo.checks} />
      </div>
      <Testimonials
        h2={"Don't take our word. \nSee why customers love us."}
        testimonials={testimonials}
        path="Employment"
      />
      <GetStarted
        img="Home/get-started.svg"
        h3={"Get started with the \nbest-in-class verification \nexperience 👇"}
      />
      <Footer />
    </div>
  );
};
