import React from "react";
import Styles from "./Check.module.scss";

const Checks = (props) => {
  return (
    <>
      <div className={Styles.CheckWrap}>
        <section>
          <div className={Styles.CheckWrapHeader}>
            <h2>All the checks you need in one place</h2>
          </div>
          <div className={Styles.CheckInnerWrap}>
            {props.data.map((data, index) => {
              return (
                <a href={data.link} className={Styles.CheckCard} key={index}>
                  <div className={Styles.CheckCardIcon}>
                    <img
                      src={require(`../../../images/LP/Home/checks/${data.path}.svg`)}
                    />
                  </div>
                  <h6>{data.title}</h6>
                </a>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default Checks;
